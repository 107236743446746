<template>
  <div class="layout-wrapper">
    <div
      class="nav-head nav-head-blue"
      :class="{
        'nav-head-blue':
          top >= 200 ||
          $route.path.includes('yjyNewsDetail') ||
          $route.path.includes('yjyExampleDetail'),
      }"
      v-show="showTop"
    >
      <div class="wp nav-head-box">
        <div class="nav-head-box-logo">
          <img src="../../assets/icon/yjyLogo.png" />
          <!-- <img
            v-if="
              top <= 200 &&
              !$route.path.includes('yjyNewsDetail') &&
              !$route.path.includes('yjyExampleDetail')
            "
            src="../../assets/icon/yjyWLogo.png"
          />
          <img v-else src="../../assets/icon/yjyLogo.png" /> -->
        </div>
        <div class="nav-head-box-menu">
          <ul>
            <li
              v-for="item in yjyRouters"
              @click="navItem(item)"
              :key="item.home"
              :class="{ active: $route.path.includes(item.path) }"
            >
              <a>{{ item.title }}<span></span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="body">
      <keep-alive>
        <router-view v-if="isKeepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!isKeepAlive"></router-view>
    </div>

    <div class="side-box">
      <div class="side2">
        <ul>
          <li>
            <a @click="goTop" class="sidetop"
              ><img src="../../assets/front/r_icon2.png"
            /></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="nav-bottom">
      <div class="nav-bottom-text">
        主管单位：江西省工业和信息化厅 &nbsp;&nbsp;
        主办单位：江西省云智大数据产业研究院
        <br />
        联系邮编：330009
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp; 联系邮箱：JXYZRI2020@126.COM
        <br />
        联系地址：江西省南昌市红谷滩区九龙大道1388号中国南昌VR产业基地3栋6层
        <br />

        机构代码：52360000MJC721925W
      </div>
    </div>
  </div>
</template>
<script>
import yjyRouters from '@/router/yjyRouters';
// function throttle(fn, delay = 200) {
//   let valid = true;
//   return function () {
//     if (!valid) {
//       //休息时间 暂不接客
//       return false;
//     }
//     // 工作时间，执行函数并且在间隔期内把状态位设为无效
//     valid = false;
//     setTimeout(() => {
//       fn();
//       valid = true;
//     }, delay);
//   };
// }

export default {
  data() {
    return {
      yjyRouters: yjyRouters.filter((i) => !i.hidden),
      show: false,
      logoClass: '',
      showTop: true,
      showTel: false,
      top: 0,
    };
  },
  mounted() {
    (function (w, d, n, a, j) {
      w[n] =
        w[n] ||
        function () {
          (w[n].a = w[n].a || []).push(arguments);
        };
      j = d.createElement('script');
      j.async = true;
      j.src = 'https://qiyukf.com/script/65bd4701323a30f9bee43f0f870820eb.js';
      d.body.appendChild(j);
    })(window, document, 'ysf');

    window.ysf('config', {
      uid: '',
      name: '',
      staffid: '6023553', // 客服id
      groupid: '480816728', // 客服组id
    });

    // const vm = this;
    window.addEventListener('scroll', () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
  },
  watch: {
    $route(to, from) {
      console.log('路由变化了');
      console.log(to);
      console.log(from);
      this.showTop = true;
    },
    top(newValue, oldValue) {
      if (newValue > 100) {
        if (newValue > oldValue) {
          this.showTop = false;
        } else {
          this.showTop = true;
        }
      }
    },
  },
  computed: {
    // 当前页面原本显示的 logo
    currentPage() {
      return this.$route.name === 'home' ? 'white' : 'red';
    },
    isKeepAlive() {
      return this.$route.meta.keep === false ? false : true;
    },
  },
  methods: {
    openSdk() {
      window.ysf('open');
    },
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    navItem(item) {
      this.logoClass = '';
      this.show = false;
      if (item.name !== this.$route.name) {
        this.$router.push(item.path);
      }
    },
    getActiveStatus(item) {
      return this.$route.name.indexOf(item.name) >= 0 ? 'active' : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .nav-head {
    height: 90px;
    //background: #fff;
    //box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .nav-head-box {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .nav-head-box-logo {
    margin-right: 65px;
    img {
      width: 200px;
      height: 44px;
    }
  }

  .nav-head-box-menu {
    ul {
      display: flex;
      flex-direction: row;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 37px;
        a {
          position: relative;
          height: 50px;
          color: #fff;
          font-size: 20px;
          span {
            position: absolute;
            top: 47px;
            left: 0;
            width: 0;
            height: 3px;
            background-color: #fff;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }

  .nav-head-box-menu ul li.active a span {
    width: 100%;
  }

  .nav-head-box-menu ul li:hover a span {
    width: 100%;
  }

  .nav-head-blue {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    background: white;
    .nav-head-box-menu {
      ul {
        display: flex;
        flex-direction: row;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 37px;
          a {
            position: relative;
            height: 50px;
            color: #333;
            font-size: 20px;
            span {
              position: absolute;
              top: 47px;
              left: 0;
              width: 0;
              height: 3px;
              background-color: #1f73ff;
              transition: all 0.2s linear;
            }
          }
        }
      }
    }

    .nav-head-box-menu ul li.active a span {
      width: 100%;
    }

    .nav-head-box-menu ul li:hover a span {
      width: 100%;
    }
    .nav-head-box-menu ul li:hover a {
      color: #1f73ff;
    }
    ul li.active a {
      width: 100%;
      color: #1f73ff;
    }
    ul li.active a span {
      background-color: #1f73ff;
    }
  }

  .body {
  }

  /* side */
  .side {
    position: fixed;
    width: 50px;
    height: 275px;
    right: 0;
    top: 250px;
    z-index: 100;
  }
  .side ul li {
    width: 50px;
    height: 50px;
    float: left;
    position: relative;
    margin-bottom: 2px;
  }
  .side ul li .sidebox {
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px 0 0 5px;
    top: 0;
    right: 0;
    transition: all 0.3s;
    background: #000022;
    color: #fff;
    font: 16px;
    overflow: hidden;
  }
  .side ul li a:hover .sidebox {
    width: 175px;
    background: rgb(228, 57, 42);
    padding-left: 17px;
  }
  .side ul li .sidetop {
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    background: #000;
    opacity: 0.8;
    filter: Alpha(opacity=80);
    transition: all 0.3s;
  }
  .side ul li .sidetop:hover {
    background: #1071b5;
  }
  .side ul li img {
    float: right;
  }

  .side2 {
    position: fixed;
    width: 40px;
    right: 10px;
    z-index: 100;
    top: 500px;
    height: 80px;
  }
  .side2 ul li {
    width: 40px;
    height: 40px;
  }
  .side2 ul li a {
    display: block;
    background-color: #1071b5;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
  }
  .side2 ul li a img {
    vertical-align: middle;
    display: inline-block;
  }
  .side2 ul li a:hover {
    background-color: #000022;
  }
  .side2 ul li:hover .weixin {
    height: 174px;
    opacity: 1;
  }
  .side2 ul li .weixin {
    position: absolute;
    left: -154px;
    height: 134px;
    bottom: 0;
    text-align: center;
    padding-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(153, 153, 153, 0.3);
    opacity: 0;
    transition: all 0.3s;
    transition-property: height;
  }
  .side2 ul li .weixin em {
    background: url(../../assets/front/arr2.png) no-repeat;
    width: 5px;
    height: 17px;
    display: block;
    position: absolute;
    right: -5px;
    top: 50%;
    margin-top: 3px;
  }
  .side2 ul li .weixin p {
    font: 12px/14px 'microsoft yahei';
    color: #666;
  }
  .side2 ul li .weixin img {
    display: block;
  }

  .nav-bottom {
    padding: 16px 0;
    background: #000022;
    display: flex;
    justify-content: center;
  }

  .nav-bottom-text {
    color: #999999;
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
