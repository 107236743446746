<template>
  <div class="part-title">
    <div class="zh" :style="{ color: zhColor }">{{ zh }}</div>
    <div class="line" v-show="line"></div>
    <div class="en" :style="{ color: enColor }">{{ en.toUpperCase() }}</div>
  </div>
</template>
<script>
// 段落标题
export default {
  name: 'part-title',
  props: {
    line: {
      type: Boolean,
      default: false,
    },
    zh: {
      type: String,
      default: '中文标题',
    },
    en: {
      type: String,
      default: 'Chinese Title',
    },
    zhColor: {
      type: String,
      default: '#1482ff',
    },
    enColor: {
      type: String,
      default: '#999999',
    },
  },
};
</script>
<style lang="scss" scoped>
.part-title {
  .zh {
    text-align: center;
    font-size: 1.9rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 2.6rem;
    color: #1482ff;
    padding-bottom: 0.4rem;
  }
  .line {
    height: 1px;
    width: 3.6rem;
    background-color: #fff;
    margin: 1rem auto 1.2rem;
  }
  .en {
    text-align: center;
    height: 1.4rem;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 1.4rem;
    color: #999999;
    letter-spacing: 0.1rem;
  }
}
</style>
