import Vue from 'vue';
import VueRouter from 'vue-router';

// 融合科技官网
import layout from '../views/layout';
import constantRoutes from './navRouters';

// 研究院官网
import yjyPage from '../views/layout/yjyPage';
import yjyRouters from './yjyRouters';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    redirect: 'home',
    component: layout,
    children: constantRoutes,
  },
  {
    path: '/yjyHome',
    redirect: 'yjyHome',
    component: yjyPage,
    children: yjyRouters,
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: process.env.NODE_ENV === 'development' ? '' : 'rh-front',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
