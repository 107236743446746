/**
 * 全局组件注册
 */
import Vue from 'vue';

const requireComponent = require.context('.', true, /^\S+\.vue$/);
requireComponent.keys().forEach((pathName) => {
  const componentPaths = pathName.split('/');
  let componentName;
  if (componentPaths.length === 2) {
    componentName = componentPaths[1].replace(/\.vue$/, '');
  }

  if (
    componentPaths.length === 3 &&
    componentPaths[componentPaths.length - 1] === 'index.vue'
  ) {
    componentName = componentPaths[1];
  }

  if (componentName) {
    const componentConfig = requireComponent(pathName);
    Vue.component(componentName, componentConfig.default || componentConfig);
  }
});
