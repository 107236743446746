export default [
  {
    path: '/home',
    name: 'home',
    title: '首页',
    component: () => import('../views/home'),
  },
  {
    path: '/about',
    name: 'about',
    title: '关于我们',
    component: () => import('../views/about'),
  },
  {
    path: '/product',
    name: 'product',
    title: '产品服务',
    component: () => import('../views/product'),
  },
  {
    path: '/example',
    name: 'example',
    title: '客户案例',
    component: () => import('@/views/example'),
  },
  {
    path: '/news',
    name: 'news',
    title: '新闻动态',
    component: () => import('../views/news'),
  },
  {
    path: '/contant',
    name: 'contant',
    title: '联系我们',
    component: () => import('../views/contant'),
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    hidden: true,
    title: '新闻动态-详情',
    meta: {
      keep: false,
    },
    component: () => import('@/views/news/detail'),
  },
];
