<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    getRootFontSize() {
      let size = Math.round((document.body.clientWidth / 375) * 10);
      size = size > 20 ? 20 : size < 8 ? 8 : size;
      document.documentElement.style.fontSize = `${size}px`;
    },
  },
  mounted() {
    // this.getRootFontSize();
    window.addEventListener('resize', this.getRootFontSize);
  },
};
</script>
