<template>
  <div class="self-image" :style="{ width, height }">
    <img :src="ImageObject[imgKey]" alt="" v-on="$listeners" />
  </div>
</template>
<script>
// 自定义图片组件
const requireImgs = require.context('../assets', true, /^\S+\.png$/);
const ImageObject = {};
requireImgs.keys().map((i) => {
  ImageObject[i.split('.')[1]] = requireImgs(i);
});

export default {
  name: 'part-title',
  props: {
    width: {
      type: String,
      default: '4rem',
    },
    height: {
      type: String,
      default: '4rem',
    },
    type: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      default: 'tel',
    },
  },
  computed: {
    imgKey() {
      return `/${this.type}/${this.name}`;
    },
  },
  data() {
    return { ImageObject };
  },
};
</script>
<style lang="scss" scoped>
.self-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
