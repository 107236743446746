import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/style/index.scss';
import vant from 'vant/es';
import 'vant/lib/index.css';
import './components/globalRegister';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(vant);
Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */);
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
