<template>
  <div class="self-nav">
    <div
      class="nav-item"
      v-for="(item, index) in list"
      :class="getActive(index)"
      @click="setActive(index)"
      :key="item"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
// 段落标题
export default {
  name: 'part-title',
  props: {
    list: {
      type: Array,
      default() {
        return ['选项1', '选项2'];
      },
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setActive(active) {
      this.$emit('update:active', active);
    },
    getActive(index) {
      return index === this.active ? 'active' : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.self-nav {
  width: 100%;
  display: flex;
  line-height: 4rem;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
  .nav-item {
    flex: 1;
    text-align: center;
    position: relative;
    transition: all 0.3s;
    color: #999999;
    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 2px;
      width: 0;
      transition: all 0.3s;
      transform: translateX(-50%);
      background-color: #1482ff;
    }
    &.active {
      color: #333333;
      &::after {
        width: 8rem;
      }
    }
  }
}
</style>
