export default [
  {
    path: '/yjyHome',
    name: 'yjyHome',
    title: '首页',
    component: () => import('../views/researchInstitute/home'),
  },
  {
    path: '/yjyNews',
    name: 'yjyNews',
    title: '新闻动态',
    component: () => import('../views/researchInstitute/news'),
  },
  {
    path: '/yjyNewsDetail',
    name: 'yjyNewsDetail',
    hidden: true,
    title: '新闻动态-详情',
    meta: {
      keep: false,
    },
    component: () => import('../views/researchInstitute/news/detail'),
  },
  {
    path: '/yjyExample',
    name: 'yjyExample',
    title: '政策制度',
    component: () => import('../views/researchInstitute/example'),
  },
  {
    path: '/yjyExampleDetail',
    name: 'yjyExampleDetail',
    hidden: true,
    title: '政策制度-详情',
    meta: {
      keep: false,
    },
    component: () => import('../views/researchInstitute/example/detail'),
  },
  {
    path: '/yjyProduct',
    name: 'yjyProduct',
    title: '工作品牌',
    component: () => import('../views/researchInstitute/product'),
  },
  {
    path: '/yjyAbout',
    name: 'yjyAbout',
    title: '组织架构',
    component: () => import('../views/researchInstitute/about'),
  },



  // {
  //   path: '/yjyContant',
  //   name: 'yjyContant',
  //   title: '联系我们',
  //   component: () => import('../views/researchInstitute/contant'),
  // },

];
